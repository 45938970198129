import { CurrencyEnum, type CurrencyEnumEnumType } from 'types/enums.ts'

// Down here all the arrays with the currency data from the official webs
// All currencys from: https://v6.exchangerate-api.com/v6/f3ae4ce3d6c49c8b35cc5f81/latest/EUR
// Paypal info: https://developer.paypal.com/docs/reports/reference/paypal-supported-currencies/
// Stripe info: https://docs.stripe.com/currencies?locale=es-ES&presentment-currency=ES

const allCurrencyNames = Object.values(CurrencyEnum)

const allPaypalCurrencyNames: CurrencyEnumEnumType[] = [
  'AUD',
  'BRL',
  'CAD',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'HKD',
  'HUF',
  'ILS',
  'JPY',
  'MYR',
  'MXN',
  'TWD',
  'NZD',
  'NOK',
  'PHP',
  'PLN',
  'GBP',
  'SGD',
  'SEK',
  'CHF',
  'THB',
  'USD',
]
const allStripeCurrencyNames: CurrencyEnumEnumType[] = [
  'USD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JMD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KRW',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MUR',
  'MVR',
  'MWK',
  // 'MXN', paypal don't support it
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SEK',
  'SGD',
  'SHP',
  'SLE',
  'SOS',
  'SRD',
  'SZL',
  'THB',
  'TJS',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW',
]

const paypalNoDecimals: CurrencyEnumEnumType[] = ['HUF', 'JPY', 'TWD'] // This currency does not support decimals. If you pass a decimal amount, an error occurs.
const paypalOutOfCountryFee: CurrencyEnumEnumType[] = ['BRL'] // Is supported, but if the receiver is not in Brazil will have to pay a fee for the conversion
const paypalOnlyInCountry: CurrencyEnumEnumType[] = ['MYR'] // This currency is supported as a payment currency and a currency balance for in-country PayPal accounts only.
const paypalNotPayment: CurrencyEnumEnumType[] = ['CNY'] // Currency balance for in-country PayPal accounts only.
const paypalIncompatibilities: CurrencyEnumEnumType[] = [
  ...paypalOutOfCountryFee,
  ...paypalOnlyInCountry,
  ...paypalNotPayment,
]

const stripeSpecialCases: CurrencyEnumEnumType[] = ['ISK', 'HUF', 'TWD', 'UGX'] //divisibles por 100 y 2 decimales
const stripeNoDecimals: CurrencyEnumEnumType[] = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
]
const stripeThreeDecimals: CurrencyEnumEnumType[] = [
  'BHD',
  'JOD',
  'KWD',
  'OMR',
  'TND',
]
const stripeAmericanExpressNotCompatible: CurrencyEnumEnumType[] = [
  'AFN',
  'AOA',
  'ARS',
  'BOB',
  'BRL',
  'CLP',
  'COP',
  'CRC',
  'CVE',
  'DJF',
  'FKP',
  'GNF',
  'GTQ',
  'HNL',
  'LAK',
  'MUR',
  'NIO',
  'PAB',
  'PEN',
  'PYG',
  'SHP',
  'SRD',
  'UYU',
  'XOF',
  'XPF',
]

const miisticoCurrencies: CurrencyEnumEnumType[] = [
  'EUR', // euros
  'USD', // dolar
  'MXN', // peso mexicano
  'COP', // peso colombiano
  'PEN', // sol peruano
  'GBP', // libra esterlina
  'CLP', // peso chileno
  'ARS', // peso argentino
  'UYU', // peso uruguayo
  'DOP', // peso dominicano
]

export const Currencies = {
  allCurrency: allCurrencyNames,
  miistico: miisticoCurrencies,
  paypal: {
    currencies: allPaypalCurrencyNames,
    noDecimal: paypalNoDecimals,
    noCompatible: paypalIncompatibilities,
  },
  stripe: {
    currencies: allStripeCurrencyNames,
    noDecimal: stripeNoDecimals,
    threeDecimals: stripeThreeDecimals,
    specialCases: stripeSpecialCases,
    americanExpressIncompatibility: stripeAmericanExpressNotCompatible,
  },
} as const
