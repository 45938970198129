import { z } from 'zod'
import { isValidDate } from './strings.ts'
import { IVAEnum, IrpfEnum } from 'types/enums.ts'
import { Currencies } from '~/features/currency/currencies.ts'

export const passwordSchema = z
  .string()
  .min(6, { message: 'La contraseña es demasiado corta' })
  .max(100, { message: 'La contraseña es demasiado larga' })
  .refine(s => !s.includes(' '), 'Los espacios no son validos')
export const firstNameSchema = z
  .string()
  .min(2, { message: 'El nombre es demasiado corto' })
  .max(40, { message: 'El nombre es demasiado largo' })
export const lastNameSchema = z
  .string()
  .min(2, { message: 'Los apellidos es demasiado corto' })
  .max(40, { message: 'Los apellidos es demasiado largo' })

export const nicknameSchema = z
  .string()
  .min(2, { message: 'El nickname es demasiado corto' })
  .max(40, { message: 'El nickname es demasiado largo' })

export const birthDateSchema = z.string().refine(value => isValidDate(value), {
  message: 'Fecha de nacimiento invalida',
})

export const dateSchemaOptional = z
  .string()
  .refine(value => isValidDate(value), {
    message: 'Fecha de nacimiento invalida',
  })
  .optional()

export const countrySchema = z.string().optional()
export const postalCodeSchema = z
  .string()
  .min(3, { message: 'El Código postal es demasiado corto' })

export const postalCodeSchemaOptional = z
  .string()
  .min(3, { message: 'El Código postal es demasiado corto' })
  .optional()

export const emailSchema = z
  .string()
  .email({ message: 'El email no es valido' })
  .min(3, { message: 'El email es demasiado corto' })
  .max(100, { message: 'El email es demasiado largo' })
export const confirmOptionalSchema = z.string().optional()
export const phoneSchema = z
  .string()
  .optional()
  .refine(
    value => {
      // Check if the value is present and its length is at least 3
      return !value || value.length >= 3
    },
    {
      message: 'El número de telefono es demasiado corto',
    },
  )
export const citySchema = z
  .string()
  .min(3, { message: 'El nombre de ciudad es demasiado es demasiado corto' })
export const languagesSchema = z
  .string()
  .min(3, { message: 'El nombre de lenguage es demasiado es demasiado corto' })
export const expertiseSchema = z.string()
// .min(3, { message: 'El expertise es demasiado es demasiado corto' })

export const ivaOptions = [IVAEnum.YES, IVAEnum.NO] as const
export const ivaSchema = z.enum(ivaOptions)

const irpfOptions = [`${IrpfEnum['7%']}`, `${IrpfEnum['15%']}`] as const
export const irpfSchema = z.enum(irpfOptions)

export const billingAddressSchema = z.string()
export const billingNameSchema = z.string()

export const nifSchema = z.string().min(5)
export const ivaSelectOptions: string[] = [...ivaOptions]
export const irpfSelectOptions: string[] = [...irpfOptions]

export const currencySchema = z.enum(
  Currencies.miistico as [string, ...string[]],
)

export const currencySelectOptions: string[] = [...Currencies.miistico]
